<template>
  <div>
    <div v-if="isLoaded" v-show="isAlertDuplicate" class="alert-container">
      <div class="alert-body">
        <div @click="isAlertDuplicate = !isAlertDuplicate" class="edit-quit"></div>
        <p>Hold on one sec...</p>
        <p><b>Please try to avoid duplicate wishes.</b></p>
        <br>
        <p>Browse other student's posts first to see if anyone already posted the same thing.</p>
        <div class="alert-buttons">
          <div @click="isAlertDuplicate = !isAlertDuplicate" class="alert-back">Back</div>
          <div @click="isEdit = !isEdit; isAlertDuplicate = !isAlertDuplicate" class="alert-proceed">Proceed</div>
        </div>
      </div>
    </div>

    <div class="WishCompany-container">
      <div class="back-top" id="opp-back-top" @click="handleBack">
        <div>
          <ion-icon name="arrow-back-outline" class="big-icon"></ion-icon>
        </div>
        <h2>Back</h2>
      </div>
      <hr class="good-line" />
      <h1 class="company-name">{{companyName}}</h1>
      <div class="top-bar">
        <div>
          <ion-icon name="people-outline" class="small-icon"></ion-icon>
        </div>
        <h4 v-if="alumniNum==0">There are no alumni currently working here.</h4>
        <h4 v-else>There <p v-if="alumniNum>1">are</p><p v-if="alumniNum==1">is</p> {{alumniNum}} alumni currently working here.</h4>
        <button @click="isAlertDuplicate=!isAlertDuplicate">New Request</button>
        <WishEdit
          @exitEdit="toggleEdit"
          v-if="isEdit"
          :companyId="companyId"
          :companyName="companyName"
          :isNew="true"
        />
      </div>
      <div class="second-bar">
        <div class="filter-btn">
          <button
            :class="{
              'filter-btn-unclicked': !isFiltering,
              'filter-btn-clicked': isFiltering,
            }"
            @click="isFiltering = !isFiltering"
          >
            <h4 v-if="!isFiltering">Show Additional Filters</h4>
            <h4 v-else>Hide Additional Filters</h4>
          </button>
        </div>
        <div class="checkbox">
          <input type="checkbox" @click="handleShowMineOnly" />
          <h4>Show only my posts</h4>
        </div>
      </div>
      <Filter v-if="isFiltering" @apply-wish-filters="handleFilters" :isForHome="false" />
      <h2 class="title">Active Wishes</h2>
      <div v-if="!filteredWishes[0]" class="default-wishes">
        <h3>There are no active wishes for this company yet.</h3>
      </div>
      <div class="Wishes">
        <WishCard
          v-for="wish in filteredWishes"
          @exitEdit="toggleEdit"
          @exitManage="handleReload"
          @change-target-chatter="changeTargetChatter"
          :key="wish.wishId"
          :wish="wish"
          :companyName="companyName"
          :companyId="companyId"
        />
      </div>

      <div class="expired-wishes" v-if="myExpiredWishes.length">
        <h2>Your Expired Wishes</h2>
        <WishCard
          v-for="wish in myExpiredWishes"
          @exitEdit="toggleEdit"
          @exitManage="handleReload"
          @change-target-chatter="changeTargetChatter"
          :isExpired="true"
          :statusOverrule="true"
          :key="wish.wishId"
          :wish="wish"
          :companyName="companyName"
          :companyId="companyId"
        />
      </div>
    </div>
  </div>
  
</template>

<script>
import { useRouter } from "vue-router";
import { ref, watchEffect, onMounted } from "vue";
import firebase from "firebase/app";
import "firebase/firestore";

import getUser from "@/composables/getUser";
import getWishesForOneFirm from "@/composables/WishList/getWishesForOneFirm";
import getAlumniNum from "@/composables/WishList/getAlumniNum.js";
import modifyDate from "@/composables/modifyDate.js";

import WishCard from "@/components/WishList/wishCard.vue";
import WishEdit from "@/components/WishList/wishEdit.vue";
import Filter from "@/components/WishList/wishFilter.vue";

export default {
	components:{
		WishCard,
		WishEdit,
		Filter,
	},
	props: ["companyName","companyId"],
  emits: ["change-target-chatter"],
	async setup(props, { emit }) {
		const { user } = getUser();
    const isDefault = ref(false);
		const isShowingMineOnly = ref(false);
		const isEdit = ref(false);
		const isFiltering = ref(false);
		const router = useRouter();
		const wishes = ref([]);
		const filteredWishes = ref([]);
    const myExpiredWishes = ref([]);
    const alumniNum = ref(0);
		const noSpecial = ref(null);
		const isAlertDuplicate = ref(false);
		noSpecial.value = /[$&+,:;=?[\]@#|{}'<>.^*()%!-/]/;
    const isLoaded = ref(false);


		const handleLoadWishes = async () => {
      const now = new firebase.firestore.Timestamp.now();
			await getAlumniNum(alumniNum, props.companyId);
			await getWishesForOneFirm(wishes,props.companyId);
			wishes.value.forEach(wish => {
				// console.log(wish);
        if ((now.seconds-wish.lastActivityTime.seconds) > 60*60*24*90){
          wish.isExpired = true;
        } else if ((now.seconds-wish.lastActivityTime.seconds) > 60*60*24*60){
          wish.isSoonToExpire = true;
        }
				wish.candidates.forEach(candidate => {
					// console.log(candidate.uid);
					if (candidate.uid === user.value.uid){
						wish.isMine = true;
            wish.lastActivity = modifyDate(wish.lastActivityTime.toDate());
						wish.isDefaultResume = candidate.isDefaultResume;
						// wish.resumeUrl = candidate.resumeUrl;
            wish.messageTargetId= candidate.acceptedBy;
					}
				})
			})
			// console.log("handleLoadWishes completed");
		}

    onMounted(async () => {
      await handleLoadWishes();
		  filteredWishes.value = wishes.value.filter(wish => {
        if (!wish.isExpired){
          return true;
        }
      });
      myExpiredWishes.value = wishes.value.filter(wish => {
        if (wish.isExpired && wish.isMine){
          return true;
        }
      });
      isLoaded.value = true;
    });


		const handleShowMineOnly = () => {
			if (isShowingMineOnly.value){
				isShowingMineOnly.value = false;
				filteredWishes.value = wishes.value;
			} else {
				isShowingMineOnly.value = true;
				filteredWishes.value = wishes.value.filter(wish => {
					if (wish.isMine){
						return true;
					} else {
						return false;
					}
				})
			}
		}

		const handleFilters = (filters) => {
			filteredWishes.value = wishes.value;
			if (filters.state) {
        filteredWishes.value = filteredWishes.value.filter(
          (wish) => {
            return filters.state.includes(wish.state);
          }
        );
      }
      if (filters.position) {
        filteredWishes.value = wishes.value.filter((wish) => {
          if (filters.position == null) {
            return true;
          } else if (noSpecial.value.test(filters.position)) {
            alert("Please do not type in special characters.");
            return true;
          }
          return wish.position.toLowerCase().match(filters.position.toLowerCase());
        });
      }
		}


		const handleBack = () => {
      router.go(-1);
    };

    const changeTargetChatter = (chatterId) => {
      emit("change-target-chatter", chatterId);
    }

		const toggleEdit = async (isChildEdit) => {
			// alert("isChildEdit: ", isChildEdit);
      isEdit.value = false;
			if (isChildEdit==false){
				router.go();
			}
    }

		const handleReload = () => {
			router.go();
		}

    return {
      isLoaded,
			user,
			isDefault,
			isShowingMineOnly,
			isEdit,
      isAlertDuplicate,
			isFiltering,
			alumniNum,
			wishes,
			filteredWishes,
      myExpiredWishes,
			handleShowMineOnly,
			handleReload,
			handleFilters,
			handleBack,
			toggleEdit,
      changeTargetChatter
		};
  },
}
</script>

<style scoped>
.WishCompany-container{
	width: 90%;
  margin: 5vh auto 5vh auto;
}

.company-name{
	margin-top: 3vh;
}

.top-bar{
	margin-top: 3vh;
	display: flex;
	align-items: center;
}

.top-bar div{
	flex: 0 1 7.5%;
	color: var(--purple);
}

.top-bar h4{
	flex: 0 1 75%;
  font-weight: 400;
}

.top-bar h4 p{
  display: inline;
}

.top-bar button{
	flex: 0 1 20%;
  color: white;
	background-color: var(--purple-l);
	border-radius: 15px;
	border: none;
	padding: 2vh;
}

.top-bar button:hover{
  cursor: pointer;
}

.second-bar{
	display: flex;
	margin-top: 3vh;
	text-align: center;
}

.title{
	margin-top: 5vh;
}

.checkbox {
	width: 20%;
	margin-left: auto;
	margin-right: 0;
  display: flex;
  align-items: center;
	justify-content: center;
}

.expired-wishes{
  margin-top: 5vh;
  margin-bottom: 5vh;
  padding: 1vh;
  border: 0.3vh var(--red) solid;
  border-radius: 15px;
}

.default-wishes {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.default-wishes h3 {
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  margin: 13vh 0 15vh 0;
}

@media screen and (max-width: 600px) {
  .checkbox {
    width: 50%;
  }

  .top-bar button{
    padding: 1vh;
  }

  .top-bar h4 {
    margin-left: 3vw;
  }
}

.checkbox input {
	margin-left: 0.75vw;
  flex: 0 1 20%;
  height: 35px;
  width: 35px;
  color: #cca2d8;
}

.checkbox input:checked {
  background-color: #cca2d8;
}

.checkbox input:hover {
  cursor: pointer;
}

.checkbox h4 {
	font-size: 80%;
	margin-left: 0.25vw;
  flex: 0 0 80%;
}

.filter-btn {
  flex: 0 1 40%;
  text-align: left;
  transition: all 0.25s ease;
}

.filter-btn-unclicked {
  background-color: white;
  color: var(--purple-l);
  padding: 0.5vh 1vw;
  border: 0.3vh var(--purple-l) solid;
  border-radius: 1vh;
  transition: all 0.25s ease;
}

.filter-btn-unclicked:hover {
  color: var(--purple);
  border-color: var(--purple);
  cursor: pointer;
}

.filter-btn-clicked {
  background-color: var(--purple-l);
  color: white;
  padding: 0.5vh 1vw;
  border: 0.3vh var(--purple-l) solid;
  border-radius: 1vh;
  transition: all 0.25s ease;
}

.filter-btn-clicked:hover {
  border-color: var(--purple-400);
  background-color: var(--purple-400);
  cursor: pointer;
}

.filter-clear h4 {
  white-space: nowrap;
}

.alert-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 5;
  background-color: rgba(10, 7, 24, 0.7);
  animation: ease-in-out fadein 0.25s;
}

.alert-body {
  position: relative;
  width: 30vw;
  margin: 25vh auto 0 auto;
  background-color: white;
  padding: 7vh 4vw 4vh 4vw;
  border-radius: 17px;
}

.edit-quit {
  position: absolute;
  top: 2vh;
  right: 1vw;
  display: inline-block;
  width: 25px;
  height: 25px;
  overflow: hidden;
}

.edit-quit::before,
.edit-quit::after {
  content: '';
  position: absolute;
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  background: #000;
  height: 3px;
  margin-top: -4px;
}
.edit-quit::before {
  transform: rotate(45deg);
}
.edit-quit::after {
  transform: rotate(-45deg);
}
.edit-quit:hover {
  cursor: pointer;
}

.alert-buttons {
  display: flex;
  justify-content: flex-end;
}

.alert-body p {
  font-size: var(--subheader);
}

.alert-buttons div {
  margin: 50px 10px 10px 10px;
  padding: 6px 20px 6px 20px;
  width: 6vw;
  text-align: center;
  border-radius: 7px;
  font-size: var(--text);
}

.alert-buttons div:hover {
  cursor: pointer;
}

.alert-back {
  background-color: #BFBFBF;
}

.alert-proceed {
  background-color: var(--purple-l);
  color: white;
  transition: 0.2s ease-in-out;
}

.alert-proceed:active {
  transform: translateY(5px);
}

@media screen and (max-width: 1700px) {
  .alert-body {
    width: 50vw;
    margin: 15vh auto 0 auto;
  }

  .alert-buttons div {
    width: 15vw;
  }
}

@media screen and (max-width: 600px) {
  .alert-body {
    width: 90vw;
    margin: 15vh auto 0 auto;
  }


  .alert-buttons div {
    width: 35vw;
  }
}
</style>
